@import "./themes";
@import "definitions";

.cursor-wait {
    &,
    & > *,
    & .clickable {
        cursor: wait !important;
    }
}

.text-lighter {
    color: lg-color(--gray-color-20);
}


.table__row--disabled .input-like {
    color: lg-color(--gray-color-25);
}


.icon-product-logo_dpl {
    background: transparent var(--productLogoUrl) no-repeat center center;
}

.lg-dropdown-popup.rule-editor-row-action-menu {
    .lg-dropdown-popup__group__entry__name {
        color: lg-color(--gray-color-60);
    }

    .lg-dropdown-popup__group__entry--disabled {
        .lg-dropdown-popup__group__entry__name {
            color: lg-color(--gray-color-20);
        }
    }

    .lg-dropdown-popup__group__entry:last-of-type:not(.lg-dropdown-popup__group__entry--disabled) {
        .lg-dropdown-popup__group__entry__name {
            color: lg-color(--error-color);
        }
    }
}

.table__row--dropdowns {

    lg-dropdown {
        position: relative;
        left: -12px;

        .lg-dropdown__icons {
            lg-icon {
                display: block;
                color: lg-color(--gray-color-25);
            }
        }
    }

    &:hover {
        .lg-dropdown {
            border: 1px solid lg-color(--gray-color-20);
            .lg-dropdown__arrow {
                display: block;
            }
        }
    }

    .lg-dropdown {
        &.lg-dropdown--invalid{
            border: 1px solid rgb(var(--error-color)) !important;
        }
        
        & { border: none; }

        .lg-dropdown__arrow {
            display: none;
        }      
    }
 


}

.table__row--level-separator {
    border-top: 1px solid rgb(var(--gray-color-20));
    
    &:first-of-type {
        border-top-color: transparent;
    }
    
}

.table__cell--with-icons {
    display: flex;
    align-items: flex-end;

    &.table__cell--text {
        flex: 0 1 auto;
    }

    lg-icon {
        display: flex;
        flex: 0 0 24px;
        margin-bottom: 1px;

    }
}
